<template>
  <div class="columns">
    <div class="column is-one-third">
        <card-component
          title="1. Run MFA"
          class="tile is-child"
          :displayHelp="$options.name"
        >
          <div class="has-text-centered">
            <b-button
              rounded
              class="is-primary button-shadow"
              @click.prevent="run()"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Run </span>
            </b-button>
          </div>
        </card-component>
      </div>
      <div class="column">
        <card-component title="2. Plots and numerical summaries" />
        <error :type='"notifications"' />
        <card-no-result v-show="!resultsLoaded">
          <strong>Default number of components computed by the method is 20.
          </strong> If your dataset does not allow such a large number of
          components to be computed, then the interface displays the actual
          number of computed components.
          <br>
          <strong>Important note</strong>: MFA is performed with raw values
          (data are not scaled to unit variance before they are processed).
          <strong>If you want to perform MFA on scaled data</strong> for some of
          the datasets, go to the menu “Edit / Dataset edition” and scale your
          dataset(s) before MFA.
        </card-no-result>
        <div v-if="resultsLoaded">
          <card-component>
            <b-tabs @input="displayResult">
              <b-tab-item label="Plots" icon="bar-chart" value="plots" />
              <b-tab-item label="Numeric summaries" icon="table" value="variance" />
            </b-tabs>
          </card-component>
          <card-component v-if="currentResult==='plots'">
            <plotly-list
              :data="graphicals"
            ></plotly-list>
          </card-component>
          <div v-if="currentResult==='variance'">
            <card-component
              v-for="(basicTable,index) in basicTables"
              :key="index"
              :title="basicTable.title"
            >
              <basic-table :data="basicTable"/>
            </card-component>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'

export default {
  name: 'RMFAStep',
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  props: {
    dataset: {
      type: String,
      default: null
    }
  },
  mixins: [ranalysis],
  data () {
    return {
      datasetName: null,
      currentResult: 'plots'
    }
  },
  watch: {
    dataset: function () {
      this.datasetName = this.dataset
    }
  },
  computed: {
    params: function () {
      return {
        'func_name': 'r_mfa',
        'datasetName': this.datasetName,
        'ncp': 20,
        'out_graph': true
      }
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
    },
    afterRun: function (data) {
      this.currentResult = 'plots'
      this.$emit('is-computed', {
        'MFAobjectName': data.ObjectName,
        'availableIndividuals': data.Individuals.data
      })
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
